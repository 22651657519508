import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import { TextField, Checkbox } from "@mui/material";
import { useState } from "react";
import { addReport, closeFundraise } from "../../store/fundraise/operations";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addPetition } from "../../store/petitions/operations";
import { addInitiative } from "../../store/initiatives/operations";
import { addFund } from "../../store/funds/operations";
import done from "../../assets/icons/DoneMark.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  addFeedback,
  deleteAccount,
  upgradeUserToDefender,
  upgradeUserToVolunteer,
} from "../../store/user/operations";
import { selectMessage } from "../../store/user/selectors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const ConfirmationModal = ({ modalTitle, modalText, modalButton }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <button className="button-white" onClick={handleClickOpen}>
        {modalButton}
      </button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {modalText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Відмінити</Button>
          <Button onClick={handleClose}>Підтвердити</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const CloseFundraiseModal = ({ id }) => {
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Надіслано", {
      position: "top-center",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = React.useState([false, false]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    status: "CLOSED",
    reasonForClosing: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheck = (index) => (event) => {
    const newChecked = [false, false];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(closeFundraise({ id, body: formData }));
      if (result) {
        setIsLoading(false);
        setFormData({
          status: "",
          reasonForClosing: "",
        });
        successToast();
        setOpen(false);
        document.getElementById("closeForm").reset();
      }
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Повідомити про закриття
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h2 id="modal-modal-title" className="pb-6 text-xl">
            Закриваємо збір
          </h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="closeForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[0]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(0)}
                />
                <h2>Сума зібрана, триває закупівля і доставка</h2>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-start items-center gap-2 w-full">
                  <Checkbox
                    checked={checked[1]}
                    label="Checkbox"
                    color="success"
                    size="large"
                    onChange={handleCheck(1)}
                  />
                  <h2>Збір втратив актуальність</h2>
                </div>
                <TextField
                  type="text"
                  label="Опишіть деталі"
                  required
                  multiline
                  rows={5}
                  variant="outlined"
                  size="small"
                  name="reasonForClosing"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className=" flex gap-4 self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const ReportModal = ({ id }) => {
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Надіслано", {
      position: "top-center",
    });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formDataValues, setFormDataValues] = useState({
    reportPhotoIMG: "",
    reportDescr: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "reportPhotoIMG") {
      const file = e.target.files[0];
      setImages((prevImages) => [...prevImages, { name, file }]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    Object.entries(formDataValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    images.forEach((image) => {
      formData.append(image.name, image.file);
    });
    try {
      const result = dispatch(addReport({ id, body: formData }));
      if (result) {
        setIsLoading(false);
        setFormDataValues({
          reportPhotoIMG: "",
          reportDescr: "",
        });
        successToast();
        setOpen(false);
        document.getElementById("closeForm").reset();
      }
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Оформити звіт
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h2 id="modal-modal-title" className="pb-6 text-xl">
            Оформлюємо звіт
          </h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="closeForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-start items-center gap-2 w-full">
                  <h2>Додайте декілька фото</h2>
                </div>
                <TextField
                  type="file"
                  required
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <TextField
                  type="file"
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <TextField
                  type="file"
                  variant="outlined"
                  size="small"
                  name="reportPhotoIMG"
                  onChange={handleInputChange}
                />
                <div className="flex justify-start items-center gap-2 w-full">
                  <h2>Додайте опис</h2>
                </div>
                <TextField
                  type="text"
                  required
                  multiline
                  rows={5}
                  variant="outlined"
                  size="small"
                  name="reportDescr"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className=" flex gap-4 self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const AddPetitionModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast(
      "Петиція успішно додана. Після перевірки вона зявиться на сайті. Слава Україні!",
      {
        position: "top-center",
      }
    );
  const [formData, setFormData] = useState({
    petitionTitle: "",
    petitionDescr: "",
    petitionLink: "",
    petitionAuthorName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await addPetition(formData);
      if (result) {
        successToast();
        setIsLoading(false);
        setFormData({
          petitionTitle: "",
          petitionDescr: "",
          petitionLink: "",
          petitionAuthorName: "",
        });
        document.getElementById("petitform").reset();
        setOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Додати петицію
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h2 className="flex self-center text-xl lg:text-5xl">
            Додати петицію
          </h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="petitform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва петиції"
              variant="outlined"
              required
              size="small"
              name="petitionTitle"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Опис петиції"
              variant="outlined"
              required
              size="small"
              name="petitionDescr"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання на петицію"
              variant="outlined"
              required
              size="small"
              name="petitionLink"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Автор петиції"
              variant="outlined"
              required
              size="small"
              name="petitionAuthorName"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const AddInitiativeModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast(
      "Ініціатива успішно додана. Після перевірки вона зявиться на сайті. Слава Україні!",
      {
        position: "top-center",
      }
    );

  const [formData, setFormData] = useState({
    initiativeTitle: "",
    initiativeDescr: "",
    initiativeLink: "",
    initiativeOwner: "",
    initiativeOwnerMail: "",
    initiativeOwnerPhone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await addInitiative(formData);
      if (result) {
        successToast();
        setFormData({
          initiativeTitle: "",
          initiativeDescr: "",
          initiativeLink: "",
          initiativeOwner: "",
          initiativeOwnerMail: "",
          initiativeOwnerPhone: "",
        });
        document.getElementById("initform").reset();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };

  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Додати ініціативу
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додати ініціативу
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="initform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва"
              variant="outlined"
              required
              size="small"
              name="initiativeTitle"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Опис"
              variant="outlined"
              required
              size="small"
              name="initiativeDescr"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання"
              variant="outlined"
              required
              size="small"
              name="initiativeLink"
              helperText="соц. мережі або сайт"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Власник ініціативи"
              variant="outlined"
              required
              size="small"
              name="initiativeOwner"
              helperText="формат: ПІБ"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Номер телефону"
              variant="outlined"
              required
              size="small"
              name="initiativeOwnerPhone"
              helperText="формат: +380630000000"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const AddFundModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Фонд успішно додано. Слава Україні!", {
      position: "top-center",
    });

  const [formData, setFormData] = useState({
    fundTitle: "",
    fundDescr: "",
    fundLink: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await addFund(formData);
      if (result) {
        successToast();
        setFormData({
          fundTitle: "",
          fundDescr: "",
          fundLink: "",
        });
        document.getElementById("fundform").reset();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };
  return (
    <div>
      <button className="button-black" onClick={handleOpen}>
        Додати фонд
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">Додати Фонд</h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="fundform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва"
              variant="outlined"
              required
              size="small"
              name="fundTitle"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Опис"
              variant="outlined"
              required
              size="small"
              name="fundDescr"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання"
              variant="outlined"
              required
              size="small"
              name="fundLink"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const HowItWorksModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="self-center">
      <button className="button-white" onClick={handleOpen}>
        Як це працює?
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col justify-center items-center top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-2 bg-grey">
          <h2 className="text-lg pr-5">Як це працює</h2>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Splide
            aria-label="how it works"
            className="w-[400px] bg-grey rounded-[20px]"
          >
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">01</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Військовий створює запит</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">02</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>
                  Волонтер знаходить цей запит і відгукується на нього
                </span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">03</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Вони обоє підтверджують співпрацю</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">04</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Волонтер відкриває збір</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">05</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Донатери долучаються</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">06</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Кошти зібрано - волонтер закуповує та відправляє</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">07</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Військовий отримує та підтверджує отримання</span>
              </div>
            </SplideSlide>
            <SplideSlide className="flex justify-center p-2">
              <div className="flex flex-col gap-4 p-4 bg-white rounded-2xl text-xs sm:w-full smx:w-fit">
                <div className="flex justify-between items-center">
                  <span className="opacity-50">08</span>
                  <img src={done} alt="done-icon" />
                </div>
                <span>Всі тішаться - русня вмирає</span>
              </div>
            </SplideSlide>
          </Splide>
        </Box>
      </Modal>
    </div>
  );
};

export const LoginModal = ({ title, cssClass }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://requesty.com.ua/api/v1";
  const handleFacebookLogin = () => {
    // Це перенаправлення до вашого бекенду для ініціації авторизації через Facebook
    window.location.href = `${BASE_URL}/users/auth/facebook`;
  };
  return (
    <div>
      <button className={cssClass || "button-black p-2"} onClick={handleOpen}>
        {title || "Увійти"}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-3xl p-5">
            Увійдіть за допомогою
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <a
            href={`${BASE_URL}/users/auth/google`}
            className="flex gap-6 items-center justify-center cursor-pointer p-10"
          >
            <img
              width={"40px"}
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/google/google-original.svg"
              alt="гуглРеєстрація"
            />
            Google
          </a>
          <button
            type="button"
            onClick={handleFacebookLogin}
            className="flex gap-6 items-center justify-center cursor-pointer p-10 bg-blue-600 text-white rounded"
          >
            <img
              width={"40px"}
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/facebook/facebook-original.svg"
              alt="Facebook Login"
            />
            Facebook
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export const FirstLoginModal = () => {
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { isNewUser } = useSelector((state) => state.auth.user);
  const [checked, setChecked] = React.useState([false, false, false]);
  const [showVolunteerForm, setShowVolunteerForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setTypes] = useState([]);
  const [volunteerInfo, setVolunteerInfo] = useState({
    specialization: [],
    fullName: "",
    phone: "",
    fbUrl: "",
    instaUrl: "",
  });
  const [formData, setFormData] = useState({
    role: "",
  });
  const dispatch = useDispatch();
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = (message) =>
    toast(message, {
      position: "top-center",
    });

  const handleTypeChange = (event, newTypes) => {
    setTypes(newTypes);
    setVolunteerInfo((prevData) => ({
      ...prevData,
      specialization: newTypes,
    }));
  };

  useEffect(() => {
    if (isNewUser) {
      setOpen(true);
    }
  }, [isNewUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVolunteerInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheck = (index) => (event) => {
    const newChecked = [false, false, false];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);

    const roles = ["DEFENDER", "VOLUNTEER", "USER"];
    setFormData((prevData) => ({
      ...prevData,
      role: event.target.checked ? roles[index] : "",
    }));
  };

  const handleVolunteerSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(upgradeUserToVolunteer(volunteerInfo));
      if (result) {
        successToast(
          "Супер! Ми все перевіримо і як що все добре ви зможете волонтерити!)"
        );
        setFormData({
          specialization: [],
          fullName: "",
          phone: "",
          fbUrl: "",
          instaUrl: "",
        });
        setOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.role === "DEFENDER") {
        setFormData(formData);
        successToast("Чудово, тепер ви можете створювати запити");
        setOpen(false);
      } else if (formData.role === "USER") {
        setFormData(formData);
        successToast(
          "Гаразд, тепер ви можете зберігати збори щоб стежити за ними"
        );
        setOpen(false);
      } else if (formData.role === "VOLUNTEER") {
        setShowVolunteerForm(true);
      }
      const result = dispatch(upgradeUserToDefender(formData));
      if (result) {
        setFormData({
          isNewUser: false,
          role: "",
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      className="overflow-y-scroll"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showVolunteerForm ? (
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-96 lg:w-fit rounded-[8px] p-6 bg-grey">
          <h2 className="flex text-center text-xl lg:text-3xl pt-5">
            Вітаємо на платформі Реквесті!
          </h2>
          <p className="flex self-center text-xl lg:text-3xl">
            Хто до нас завітав?
          </p>
          <form
            id="firstLoginForm"
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start gap-2 ">
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[0]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(0)}
                />
                <p>Я військовий</p>
              </div>
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[1]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(1)}
                />
                <p>Я волонтер</p>
              </div>
              <div className="flex justify-start items-center gap-2 w-full">
                <Checkbox
                  checked={checked[2]}
                  label="Checkbox"
                  color="success"
                  size="large"
                  onChange={handleCheck(2)}
                />
                <p>Я донатер</p>
              </div>
            </div>
            <button type="submit" className="button-white">
              Підтвердити
            </button>
          </form>
        </Box>
      ) : (
        <Box className="absolute flex flex-col gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-96 lg:w-fit rounded-[8px] p-6 bg-grey">
          <p className="flex text-center text-lg lg:text-3xl pt-2">
            Для доступу до волонтерського функціоналу необхідно додати наступну
            інформацію про себе.
          </p>
          <button
            type="button"
            onClick={() => {
              setShowVolunteerForm(false);
            }}
            className="absolute top-3 right-3"
          >
            <KeyboardReturnIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="newVolunteerForm"
            className="flex flex-col gap-4"
            onSubmit={handleVolunteerSubmit}
          >
            <TextField
              type="text"
              label="ПІБ"
              variant="outlined"
              required
              size="small"
              name="fullName"
              helperText="Приклад: Тарас Григорович Шевченко"
              onChange={handleInputChange}
            />
            <TextField
              type="phone"
              label="Номер телефону"
              variant="outlined"
              required
              size="small"
              name="phone"
              helperText="Приклад: +380973332211"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання facebook"
              variant="outlined"
              required
              size="small"
              name="fbUrl"
              helperText="Приклад: https://www.facebook.com/nikname"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання instagram"
              variant="outlined"
              required
              size="small"
              name="instaUrl"
              helperText="Приклад: https://www.instagram.com/nikname"
              onChange={handleInputChange}
            />
            <div className="flex flex-col items-center gap-4 px-6 bg-grey rounded-md">
              <h2>Напрямки діяльності</h2>
              <ToggleButtonGroup
                value={type}
                aria-label="Platform"
                onChange={handleTypeChange}
                size="medium"
                orientation={isMobile ? "vertical" : "horizontal"}
              >
                <ToggleButton name={"specialization"} value="Дрони">
                  Дрони
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Транспорт">
                  Транспорт
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Обладнання">
                  Обладнання
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Провізія">
                  Провізія
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Медицина">
                  Медицина
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Реабілітація">
                  Реабілітація
                </ToggleButton>
                <ToggleButton name={"specialization"} value="Інше">
                  Інше
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      )}
    </Modal>
  );
};

export const FeedbackModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = () =>
    toast("Ваша пропозиція успішно надіслана. Слава Україні!", {
      position: "top-center",
    });

  const [formData, setFormData] = useState({
    feedbackName: "",
    feedbackEmail: "",
    feedbackText: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await dispatch(addFeedback(formData));
      if (result) {
        successToast();
        setFormData({
          feedbackName: "",
          feedbackEmail: "",
          feedbackText: "",
        });
        document.getElementById("feedbackform").reset();
        setIsLoading(false);
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };
  return (
    <div>
      <button className="button-white" onClick={handleOpen}>
        Запитання / Пропозиція
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Запитання / Пропозиція
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="feedbackform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Ваше ім'я"
              variant="outlined"
              required
              size="small"
              name="feedbackName"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Ваша пошта для отримання відповіді"
              variant="outlined"
              required
              size="small"
              name="feedbackEmail"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Опис"
              variant="outlined"
              required
              size="small"
              name="feedbackText"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const DeleteAccountModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const message = useSelector(selectMessage);

  const errorToast = (message) =>
    toast.error(message, {
      position: "top-center",
    });
  const successToast = (message) =>
    toast(
      message,
      {
        position: "top-center",
      }
    );

  const [formData, setFormData] = useState({
    reasonText: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (message && message !== "") {
      successToast(message);
    }
  }, [message]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await dispatch(deleteAccount(formData));
      if (result) {
        setFormData({
          reasonText: "",
        });
        document.getElementById("feedbackform").reset();
        setIsLoading(false);
        handleClose();
      }
    } catch (error) {
      console.log("error:", error);
      setIsLoading(false);
      errorToast(error.response.data.message);
    }
  };
  return (
    <div>
      <button className="button-white" onClick={handleOpen}>
        Видалити аккаунт
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[400] rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Видалити аккаунт
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="feedbackform"
            className="flex flex-col gap-4 p-5 w-[300px] md:w-[500px] lg:w-[1000px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Причина видалення аккаунту"
              variant="outlined"
              required
              size="small"
              name="reasonText"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <button type="submit" className="button-black">
                  Надіслати
                </button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
