import instance from '../user/operations';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllFundraises = createAsyncThunk(
    'fundraises/fetchAll',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/active?page=${page}&limit=8`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchClosedFundraises = createAsyncThunk(
    'fundraises/fetchClosed',
    async ({ page }, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/inactive?page=${page}&limit=8`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getFundraiseById = createAsyncThunk(
    'fundraises/getById',
    async (id, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/active/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getFundraisesByCategory = createAsyncThunk(
    'fundraises/getFundraisesByCategory',
    async ({ page, id }, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/active?page=${page}&limit=8&category=${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getMyFundraises = createAsyncThunk(
    'fundraises/getMy',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/my-funraisings?page=${page}&limit=8`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getMyFavouriteFundraises = createAsyncThunk(
    'fundraises/getMyFavourite',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`fundraisings/get-my-favorite?page=${page}&limit=8`);
            console.log("response.data:", response.data);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const addToFavorites = createAsyncThunk(
    'fundraises/addToFavorites',
    async (id, thunkApi) => {
        try {
            const response = await instance.patch(`fundraisings/add-to-favorite/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const deleteFromFavorites = createAsyncThunk(
    'fundraises/deleteFromFavorites',
    async (id, thunkApi) => {
        try {
            const response = await instance.patch(`fundraisings/remove-from-favorite/${id}`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const addFundraise = async (body) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const result = await instance.post(`fundraisings/add-fundraising`, body, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return result.data;
    } catch (error) {
        console.error(error.response.data.message);
        throw error;
    }
};

export const closeFundraise = createAsyncThunk(
    'fundraises/closeFundraise',
    async ({ id, body }, thunkApi) => {
        try {
            const response = await instance.patch(`fundraisings/close/${id}`, body);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const addReport = createAsyncThunk(
    'fundraises/addReport',
    async ({ id, body }, thunkApi) => {
        try {
            const response = await instance.patch(`fundraisings/add-report/${id}`, body);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);